import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API } from "@shared/api.config";
import { AuthService, AuthStorageService } from "@store/auth/services";
import { Observable, catchError, first, switchMap, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  readonly doNotInterceptUrls = [API.auth.requestAuthToken()];

  constructor(
    private authService: AuthService,
    private authStorageService: AuthStorageService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.doNotInterceptUrls.some((url) => request.url.endsWith(url))) {
      return next.handle(request);
    }

    return next.handle(this.applyCredentials(request)).pipe(
      catchError((errorReponse) => {
        if (!this.isTokenError(errorReponse)) {
          return throwError(() => new Error(errorReponse.error.error));
        }

        const credentialsFromStorage = this.authStorageService.getCredentials();

        if (!credentialsFromStorage?.refresh_token) {
          console.error(
            "401: Необходимо авторизоваться. Невозможно обновить access token, т.к. отсутствует refresh token",
            errorReponse
          );
          this.router.navigateByUrl("/login");
          return throwError(() => new Error(errorReponse.error.error));
        }

        return this.authService
          .loadToken({ refreshToken: credentialsFromStorage?.refresh_token })
          .pipe(
            first(),
            switchMap((credentials) => {
              this.authStorageService.storeCredentials({
                access_token: credentials.access_token,
                refresh_token: credentials.refresh_token,
              });

              if (!credentials?.access_token || !credentials?.refresh_token) {
                console.error(
                  "401: Не удалось обновить access token",
                  errorReponse
                );
                this.router.navigateByUrl("/login");
                return throwError(() => new Error(errorReponse.error));
              }

              return next.handle(this.applyCredentials(request));
            })
          );
      })
    );
  }

  private isTokenError(error: HttpErrorResponse): boolean {
    return error instanceof HttpErrorResponse && error.status === 401;
  }

  private applyCredentials(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const credentials = this.authStorageService.getCredentials();

    return request.clone({
      setHeaders: {
        Authorization: "Bearer " + credentials?.access_token,
      },
    });
  }
}
