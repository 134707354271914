import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { AuthFacade } from "@store/facades";

export const loadUserGuard: CanActivateFn = () => {
  const facade = inject(AuthFacade);
  facade.loadUser();

  return true;
};
