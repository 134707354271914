import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ThemeSwitcherService } from "@shared/services/theme-switcher.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private themeSwitcherService: ThemeSwitcherService) {
    this.themeSwitcherService.enableTheme();
  }
}
